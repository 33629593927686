
import { defineComponent } from "vue";
import AppHeader from "@/components/theme/AppHeader.vue";
import AppFooter from "./components/theme/AppFooter.vue";
import { AppConfig } from "@/config/AppConfig";

export default defineComponent({
  components: { AppHeader, AppFooter },
  provide: {
    appConfig: AppConfig,
  },
});
