import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd6f1bba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-gray-600 body-font" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "header-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: `Home` },
        class: "title-font brand-logo"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_logo),
          _createElementVNode("span", {
            class: "ml-3 text-xl",
            textContent: _toDisplayString(_ctx.appConfig.brand.name)
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createElementVNode("nav", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.HeaderLinks, (link) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(`router-link`), {
            to: { name: link.routeName },
            class: "mr-5 hover:text-gray-900",
            key: link.title,
            textContent: _toDisplayString(link.title)
          }, null, 8, ["to", "textContent"]))
        }), 128))
      ])
    ])
  ]))
}