import AppConfigType from "@/types/AppConfig.type";

export const AppConfig: AppConfigType = {
  brand: {
    name: "Oakword.com",
    description: "",
    copyright: `&copy; Oakword.com 2021`,
  },
  social: {
    // instagram: {
    // },
  },
};

export default AppConfig;
