
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    primary: { type: String, default: `#3b82f6` },
    secondary: { type: String, default: `#ccc` },
    width: { type: Number, default: 40 },
    height: { type: Number, default: 40 },
  },
});
