
import { defineComponent } from "vue";
import HeaderLinks from "@/config/HeaderConfig";
import Logo from "@/components/theme/Logo.vue";

export default defineComponent({
  inject: ["appConfig"],
  data() {
    return { HeaderLinks };
  },
  components: {
    Logo,
  },
});
