
import { defineComponent } from "vue";
import SocialMediaLinks from "../common/SocialMediaLinks.vue";
import Logo from "./Logo.vue";

export default defineComponent({
  components: { Logo, SocialMediaLinks },

  inject: ["appConfig"],
});
