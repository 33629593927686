import HeaderConfigType from "@/types/HeaderConfig.type";

const HeaderConfig: HeaderConfigType = [
  {
    title: "Home",
    routeName: "Home",
  },
  {
    title: "About",
    routeName: "About",
  },
  {
    title: "Home",
    routeName: "Home",
  },
];

export default HeaderConfig;
